import React, { useState, useEffect } from "react"
import { useGuestApi } from "../../hooks/useApi"
import Title from "../Text/Title"
import MainContent from "../Layout/MainContent"
import BodyContent from "../Text/BodyContent"
import { variants } from "../../constants/variants"
import hero from "../../resources/images/hero-plus.png"
import withGuestLayout from "../../hoc/withGuestLayout"
import { getTitle } from "../../helpers/Publications"
import { returnLayoutSection } from "../../helpers/Layout"

function NostalgiePlusHome() {
  const { getNostalgiePlusHomePublication } = useGuestApi();
  const [content, setContent] = useState(null);

  useEffect(() => {
    getNostalgiePlusHomePublication().then(response => {
      setContent(response);
    })
  }, [getNostalgiePlusHomePublication])

  if(!content) {
    return null;
  }

  return (
    <MainContent imageInternal={hero}>
      <Title>{getTitle(content.title)}</Title>
      <BodyContent>
      {content.layout
        ? Array.isArray(content.layout.items)
          ? content.layout.items.map((item, i) => {
              return returnLayoutSection(item, i)
            })
          : null
        : null
      }
      </BodyContent>
    </MainContent>
  )
}

export default withGuestLayout(NostalgiePlusHome, {
  page: "Nostalgie Plus",
  variant: variants.plus
})
