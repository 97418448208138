import React from "react"
import { Router } from "@reach/router"
import NostalgiePlusHome from "../components/NostalgiePlus/NostalgiePlusHome";
import NostalgiePlusSub from "../components/NostalgiePlus/NostalgiePlusSub";

const Plus = () => {
  return (
    <Router>
      <NostalgiePlusHome path="nostalgie-plus" />
      <NostalgiePlusSub path="nostalgie-plus/:slug" />
    </Router>
  )
}

export default Plus
